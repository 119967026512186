import React from 'react';
import logo from '../../assets/images/logo.png';
import { Link } from 'react-router-dom';
import { useSiteState } from '../../providers/state-provider';

const Header = () => {

    const { setMenuOpen } = useSiteState();
    
    return (
        <header className="header-area header-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="header-top-two">
                            <div className="social-area">
                                {/* <ul>
                                    <li>
                                        <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
                                    </li>
                                    <li>
                                        <a href="#"><i className="fa-brands fa-twitter"></i></a>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="right-area">
                                <ul>
                                    <li><a href="/privacy">Privacy Policy</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="header-main-two header--sticky">
                            <a href="/">
                                <img src={logo} height={64} width={122} />
                            </a>
                            <div className="nav-area-main d-xl-block d-lg-block d-md-none d-sm-none d-none">
                                <nav>
                                    <ul>
                                        <li><Link to="/" className="links-main">Home</Link></li>
                                        <li><Link className="links-main" to={{ pathname: "/", hash: 'how-it-works' }}>How It Works</Link></li>
                                        <li><Link className="links-main" to={{ pathname: "/", hash: 'features' }}>Features</Link></li>
                                        <li><Link className="links-main" to={{ pathname: "/", hash: 'pricing' }}>Pricing</Link></li>
                                        <li><Link className="links-main" to="/contact">Contact</Link></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="button-area">
                                <a href="https://app.reportable.com.au">Login</a>
                                <a href="https://app.reportable.com.au/register" target='_blank' className="rts-btn btn-primary">Sign Up</a>
                            </div>
                            <div className='d-flex justify-content-center align-items-center d-lg-none'>
                                <a href="https://app.reportable.com.au" target='_blank' className="rts-btn btn-primary mr--20">Login</a>
                                <div className="menu-btn d-xl-none d-lg-none d-md-block d-sm-block" id="menu-btn" onClick={() => setMenuOpen(true)}>
                                    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect y="14" width="20" height="2" fill="#1F1F25"></rect>
                                        <rect y="7" width="20" height="2" fill="#1F1F25"></rect>
                                        <rect width="20" height="2" fill="#1F1F25"></rect>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;