import React from 'react';
import quoteImage from '../assets/images/team/brand/quote.png';
import team1 from '../assets/images/team/01.png';
import company1 from '../assets/images/team/brand/01.png'

const reviews = [
    {
        name: 'Samuel',
        role: 'Insurance Advisor',
        review: "Reportable saves me so much time with each report I have to right. It's an absolute lifesaver.",
        personImage: team1,
        companyImage: company1
    },
    {
        name: 'Samuel',
        role: 'Insurance Advisor',
        review: "Reportable saves me so much time with each report I have to right. It's an absolute lifesaver.",
        personImage: team1,
        companyImage: company1
    },
    {
        name: 'Samuel',
        role: 'Insurance Advisor',
        review: "Reportable saves me so much time with each report I have to right. It's an absolute lifesaver.",
        personImage: team1,
        companyImage: company1
    }
]

const Reviews = () => {
    return (
        <div className="rts-clients-area rts-section-gap bg-deam">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title-conter-area">
                            <span className="pre-title-bg">Our Clients</span>
                            <h2 className="title">
                                You're In Good Company <br/>
                                With <u>Reportable</u>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row g-5 mt--5">
                    {reviews.map((review, i) => {
                        return <div key={`review_${i}`} className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="single-team-area">
                                <img src={quoteImage} alt="quotes" loading="lazy" className="quote"/>
                                <div className="star">
                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <i className="fa-sharp fa-solid fa-star"></i>
                                    <i className="fa-sharp fa-solid fa-star"></i>
                                </div>
                                <p className="disc">{review.review}</p>
                                <div className="authore-area">
                                    <div className="author">
                                        <img src={team1} alt="team"/>
                                        <div className="info">
                                            <p>{review.name}</p>
                                            <span>{review.role}</span>
                                        </div>
                                    </div>
                                    <img src={company1} alt="images" loading="lazy"/>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    );
};

export default Reviews;