import React, { useCallback, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {

    const recaptchaRef = useRef();

    const [error, setError] = useState('');
    const [data, setData] = useState({
        name: '',
        email: '',
        message: '',
        captcha: ''
    });

    const sendContact = useCallback(async(e) => {
        e.preventDefault();
        setError("");
        if (!data.captcha) {
            alert("Please complete the reCaptcha.");
            return;
        }

        // const res = await fetch()
    }, [data]);

    const onChange = (key, value) => {
        setData({
            ...data, 
            [key]: value
        });
    }

    return (
        <div className="rts-contact-form-area rts-section-gap bg-deam">
            <div className="container">
                <div className="row g-5">
                    <div className="col-lg-6 offset-lg-3 col-md-12 pt--80 pt_md--45">
                        <div id="form-messages"></div>
                        <form id="contact-form" className="contact-page-form" onSubmit={sendContact}>
                            <h3 className="title">Get In Touch</h3>
                            <div className="single-input">
                                <label htmlFor="name">Your Name</label>
                                <input 
                                    id="name" 
                                    name="name" 
                                    type="text" 
                                    placeholder="Enter your full name" 
                                    required
                                    value={data.name}
                                    onChange={val => onChange("name", val)}
                                />
                                <i className="fa-regular fa-user"></i>
                            </div>
                            <div className="single-input">
                                <label htmlFor="email">Your Email Address</label>
                                <input 
                                    id="email" 
                                    name="email" 
                                    type="email" 
                                    placeholder="example@gmail.com"
                                    value={data.email}
                                    onChange={val => onChange("email", val)}
                                />
                                <i className="fa-light fa-envelope"></i>
                            </div>
                            <div className="single-input">
                                <label htmlFor="textarea">Enter Your Message</label>
                                <textarea 
                                    name="message" 
                                    id="textarea" 
                                    placeholder="Your message..."
                                    value={data.message}
                                    onChange={val => onChange("message", val)}
                                ></textarea>
                                <i className="fa-light fa-marker"></i>
                            </div>
                            <div className="single-input">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={val => onChange("captcha", val)}
                                />
                            </div>
                            <button className="rts-btn btn-primary">Send Message</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;