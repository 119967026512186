import React from 'react';
import notFoundImage from '../assets/images/case/03.png';
import { Link } from 'react-router-dom';

const PageNotFound = () => {
    return (
        <div className="rts-not-found-area rts-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="thumbanail-404 text-center">
                            <img src={notFoundImage} alt="not-found"/>
                            <div className="wrapper-para mt--45">
                                <h3 className="title">Page Not Found</h3>
                                <p className="disc">
                                    We're sorry, the page you requested could not be found. <br/> Please go back to the homepage.
                                </p>
                                <Link to="/" className="rts-btn btn-primary">Back to Home</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;