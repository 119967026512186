import React from 'react';
import { siteConfig } from '../site-config';

const Pricing = () => {
    return (
        <div className="pricing-plane-area rts-section-gapTop" id="pricing">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 offset-lg-2">
                        <div className="title-conter-area h-100 d-flex flex-column justify-content-center text-center">
                            <span className="pre-title-bg m-0 align-self-center">Pricing Plan</span>
                            <h2 className="title">
                                Simple Pricing <br/>
                                For Everyone
                            </h2>
                            <p className="para">
                                Simply put, you are charged for each report you generate. No hidden fees or any other costs.
                            </p>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className="tab-area-pricing-two mt--20">
                            <div className="tab-content mt--20">
                                <div className="tab-pane fade show active">
                                    <div className="row d-flex justify-content-center g-5 mt--10 w-100">
                                        <div className="col-8">
                                            <div className="single-pricing-single-two active">
                                                <div className="head">
                                                    <span className="top">Per-Report</span>
                                                    <div className="date-use">
                                                        <h4 className="title">$10</h4>
                                                        <span>/report</span>
                                                    </div>
                                                </div>
                                                <div className="body">
                                                    <p className="para">A pricing plan that's easy to understand and simple to predict.</p>

                                                    <div className="check-wrapper">
                                                        <div className="check-area">
                                                            <i className="fa-solid fa-check"></i>
                                                            <p>Unlimited Reports</p>
                                                        </div>
                                                        <div className="check-area">
                                                            <i className="fa-solid fa-check"></i>
                                                            <p>Saved Report History</p>
                                                        </div>
                                                        <div className="check-area">
                                                            <i className="fa-solid fa-check"></i>
                                                            <p>Multiple Report Types</p>
                                                        </div>
                                                        <div className="check-area">
                                                            <i className="fa-solid fa-check"></i>
                                                            <p>AI Assisted</p>
                                                        </div>
                                                    </div>
                                                    <a href={`${siteConfig.appUrl}/register`} className="pricing-btn">Get Started</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pricing;