import React, { useState } from 'react';
import { features } from './data';
import thumbnail from '../../assets/images/steps/04.png';

const Features = () => {

    const [active, setActive] = useState(0);
    const [video, setVideo] = useState(false);

    return (
        <div className="rts-feature-tab-area home-two rts-section-gapTop" id="features">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <span className="bg-text">New</span>
                        <div className="title-conter-area">
                            <span className="pre-title-bg">Demo</span>
                            <h2 className="title">
                                What can you do <br/>
                                with <u>Reportable</u>?
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row mt--20">
                    <div className="col-lg-12 plr--120 plr_md--60 plr_sm--15">
                        <div className="easy-freature-area-wrapper bg_image br-1">
                            <div className="row">
                                <div className="col-12">
                                    <div className="image-area-tab-content">
                                        <div className="d-flex justify-content-center">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div 
                                                    className="tab-pane fade show active"
                                                    role="tabpanel" 
                                                >
                                                    <div className="imge-thumb-vedio">
                                                        <img src={thumbnail} alt="Reportable Demo Video" loading="lazy"/>
                                                        <div className="vedio-icone">
                                                            <a 
                                                                className="play-video popup-youtube video-play-button cursor-pointer" 
                                                                onClick={() => setVideo(true)}
                                                            >
                                                                <span></span>
                                                            </a>
                                                            <div className="video-overlay">
                                                                <a className="video-overlay-close">x</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                                    <div className="tab-button-area">
                                        <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                            {features.map((feature, i) => {
                                                return <button 
                                                    key={`feature_${feature.id}`}
                                                    className={`nav-link ${i === active ? 'active' : ''} br-1`} 
                                                    id={`${feature.id}-tab`} 
                                                    data-bs-toggle="pill" 
                                                    data-bs-target={`#${feature.id}`} 
                                                    type="button" 
                                                    role="tab" 
                                                    aria-controls={feature.id} 
                                                    aria-selected={i === active}
                                                    onClick={() => setActive(i)}
                                                >
                                                    {feature.icon}
                                                    {feature.name}
                                                </button>
                                            })}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;